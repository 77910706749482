<template>
  <div class="card mt-6">
    <div class="card-header border-0 pt-6">
      <!--begin::Card title-->
      <div class="card-title">
        <h4>My Scrapes</h4>
      </div>
      <!--begin::Card title-->
    </div>
    <div class="card-body pt-0">
      <Datatable
        v-if="scrapes  && scrapes.total"
        :table-data="scrapes.data"
        :table-header="columns"
        :enable-items-per-page-dropdown="true"
        :rows-per-page-change="rowsPerPageChange"
        :page-change="pageChange"
        :total="scrapes.total"
      >
        <template v-slot:cell-job_name="{ row: scrape }">
          <router-link :to="`scrape/${scrape.id}`">{{ scrape.job_name ? scrape.job_name : "No Name" }}</router-link>
        </template>
        <template v-slot:cell-pending_websites_count="{row: scrape}">
          {{ scrape.total_websites - scrape.processed_websites_count }}
        </template>
        <template v-slot:cell-actions="{ row: scrape }">
          <a :href="`${apiUrl}/stack-spy-download/${scrape.file_location}`" target="_blank"
             class="btn btn-sm btn-primary"
             v-if="scrape.status === 'Complete'">Download <i class="fa fa-download"></i> </a>
          <span v-else>{{ scrape.status }}</span>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

export default {
  name: "MyScrapes",
  components: {
    Datatable
  },
  data() {
    return {
      checkedLists: [],
      pagination: {
        page: 1,
        per_page: 10,
        total: 0
      },
      columns: [
        {
          key: "checkbox"
        },
        {
          name: "Name",
          key: "job_name",
          sortable: true
        },
        {
          name: "Websites",
          key: "total_websites",
          sortable: false
        },
        {
          name: "Processed",
          key: "processed_websites_count",
          sortable: false
        },
        {
          name: "Pending",
          key: "pending_websites_count",
          sortable: false
        },
        {
          name: "Status",
          key: "status",
          sortable: true
        },
        {
          name: "Created",
          key: "created_date",
          sortable: true
        },
        {
          name: "Completed",
          key: "completed_at",
          sortable: true
        },
        {
          name: "Actions",
          key: "actions"
        }
      ]
    };
  },
  mounted() {
    this.getScrapes();
    store.dispatch("setBreadcrumbAction", { title: "My Scrapes" });
  },
  computed: {
    scrapes() {
      return store.getters["SiteScraper/getScrapes"];
    },
    apiUrl() {
      return process.env.VUE_APP_API_HOST
    }
  },
  watch: {
    pagination: {
      handler() {
        this.getScrapes();
      },
      deep: true
    }
  },
  methods: {
    getScrapes() {
      const queryString = Object.keys(this.pagination).map(key => key + "=" + this.pagination[key]).join("&");
      store.dispatch("SiteScraper/getScrapes", queryString);
    },
    rowsPerPageChange(perPage) {
      this.pagination.per_page = perPage;
    },
    pageChange(page) {
      this.pagination.page = page;
    }
  }
};
</script>

<style scoped>

</style>